import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allOffers: [],
  allOffersList: [],
  offerDetails: {},
  activeOffers: 0,
  expiredOffers: 0,
  offersExpiredToday: 0,
  allOffersCount: 0,
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/offer";

export const fetchAllOffers = createAsyncThunk(
  "fetch_all_offers",
  async ({
    page = 1,
    pageSize = 10,
    search = "",
    cashback = "",
    store = "",
    admin = "",
    trending = "",
    affiliation = "",
    couponCode = "",
  }) => {
    try {
      const url = `${defaultPath}/all-offers?page=${page}&pageSize=${pageSize}&search=${search}&cashback=${cashback}&couponCode=${couponCode}&affiliation=${affiliation}&store=${store}&admin=${admin}&trending=${trending}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllOffersList = createAsyncThunk(
  "fetch_all_offers_list",
  async ({ store = "", active = "" }) => {
    try {
      const { data } = await API.get(
        `${defaultPath}/all-offers-list?store=${store}&active=${active}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchOfferDetails = createAsyncThunk(
  "fetch_offer_details",
  async (offerId) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${offerId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchTrendingOffers = createAsyncThunk(
  "fetch_trending_offers",
  async () => {
    try {
      const { data } = await API.get(`${defaultPath}/trending-cashback`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);
export const createOffer = createAsyncThunk("create_Offer", async (payload) => {
  try {
    const { data } = await API.post(`${defaultPath}/create`, payload);
    return data;
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});
export const updateOffer = createAsyncThunk(
  "update_offer",
  async ({ offerId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${offerId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);
export const deleteOffer = createAsyncThunk("delete_offer", async (offerId) => {
  try {
    const { data } = await API.delete(`${defaultPath}/delete/${offerId}`);
    return data;
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});
export const updateOfferPriority = createAsyncThunk(
  "update_offer_priority",
  async ({ offerId, priority }) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/update-priority/${offerId}`,
        { priority }
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateOfferMissedStatus = createAsyncThunk(
  "update_offer_missed",
  async (offerId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/missed/status/${offerId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateOfferTrendingStatus = createAsyncThunk(
  "update_offer_trending",
  async (offerId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/trending/status/${offerId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);
const offerSlice = createSlice({
  name: "offer",
  initialState,
  extraReducers: (builder) => {
    //  all offers
    builder.addCase(fetchAllOffers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllOffers.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allOffers = action.payload.allOffers;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.pages = action.payload.pages;
        state.activeOffers = action.payload.allActiveOffers;
        state.offersExpiredToday = action.payload.offersExpiredOffersToday;
        state.expiredOffers = action.payload.allExpiredOffers;
        state.allOffersCount = action.payload.allOffersCount;
      }
    });
    // fetch store based offers
    builder.addCase(fetchAllOffersList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllOffersList.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allOffersList = action.payload.allOffers;
      }
    });
    // fetch store based offer
    builder.addCase(updateOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOffer.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"offer updated successfully"} />);
      }
    });
    // fetch store based offer
    builder.addCase(fetchOfferDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOfferDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.offerDetails = action.payload.offerDetails;
      }
    });
    //  create offer
    builder.addCase(createOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOffer.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"new offer created successfully"} />);
      }
    });
    //  delete offer
    builder.addCase(deleteOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteOffer.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allOffers = state.allOffers.map((item) => {
          if (item._id === action.payload.offer._id) {
            item.active = action.payload.offer.active;
            return item;
          }
          return item;
        });
        toast.custom(
          <Success message={" offer active status updated successfully"} />
        );
      }
    });
    // update to trending
    builder.addCase(updateOfferTrendingStatus.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateOfferTrendingStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allOffers = state.allOffers.map((item) => {
          if (item._id === action.payload.offer._id) {
            item.trending = !item.trending;
            return item;
          }
          return item;
        });
        toast.custom(
          <Success message={" offer trending status updated successfully"} />
        );
      }
    });

    // update to missing
    builder.addCase(updateOfferMissedStatus.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateOfferMissedStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allOffers = state.allOffers.map((item) => {
          if (item._id === action.payload.offer._id) {
            item.missedDeal = !item.missedDeal;
            return item;
          }
          return item;
        });
        toast.custom(
          <Success message={" offer missed status updated successfully"} />
        );
      }
    });

    // update to priority
    builder.addCase(updateOfferPriority.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOfferPriority.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allOffers = state.allOffers.map((item) => {
          if (item._id === action.payload.offer._id) {
            item.priority = action.payload.offer.priority;
            return item;
          }
          return item;
        });
        toast.custom(
          <Success message={" offer priority  updated successfully"} />
        );
      }
    });
  },
});

export const offerReducer = offerSlice.reducer;
