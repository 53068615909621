import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allTrendingOffers: [],
  trendingOfferDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/offer/trending";
const missedDealDefaultPath = "/offer";

export const fetchAllTrendingOffers = createAsyncThunk(
  "fetch_all_trending_offers",
  async ({ page = 1, pageSize = 10, search = "" }) => {
    try {
      const url = `${defaultPath}/all-offers?page=${page}&pageSize=${pageSize}&search=${search}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateTrendingPriority = createAsyncThunk(
  "update_offer_trending_priority",
  async ({ offerId, trendingPriority }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update-priority/${offerId}`,
        { trendingPriority }
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateOfferMissedStatusTrending = createAsyncThunk(
  "update_offer_missed",
  async (offerId) => {
    try {
      const { data } = await API.patch(
        `${missedDealDefaultPath}/missed/status/${offerId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const trendingOfferSlice = createSlice({
  name: "trendingOffer",
  initialState,
  extraReducers: (builder) => {
    //  cashback
    builder.addCase(fetchAllTrendingOffers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllTrendingOffers.fulfilled, (state, action) => {
      console.log(action.payload, "payload");
      state.loading = false;
      if (action?.payload?.success) {
        state.allTrendingOffers = action.payload.allTrendingOffers;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.pages = action.payload.pages;
      }
    });
    // update to trending priority
    builder.addCase(updateTrendingPriority.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTrendingPriority.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allTrendingOffers = state.allTrendingOffers.map((item) => {
          if (item._id === action.payload.cashback._id) {
            item.trendingPriority = action.payload.cashback.trendingPriority;
            return item;
          }
          return item;
        });
        toast.custom(
          <Success message={" offer trending priority updated successfully"} />
        );
      }
    });

    // update to missing
    builder.addCase(updateOfferMissedStatusTrending.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(
      updateOfferMissedStatusTrending.fulfilled,
      (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.allTrendingOffers = state.allTrendingOffers.map((item) => {
            if (item._id === action.payload.offer._id) {
              item.missedDeal = !item.missedDeal;
              return item;
            }
            return item;
          });
          toast.custom(
            <Success message={" offer missed status updated successfully"} />
          );
        }
      }
    );
  },
});

export const trendingOfferReducer = trendingOfferSlice.reducer;
